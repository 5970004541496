var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-container',[_c('data-iterator',{ref:"iterator",attrs:{"api":_vm.api,"filters":_vm.filters,"fixed-filters":_vm.fixedFilters},on:{"update:filters":[function($event){_vm.filters=$event},_vm.updateFilters]},scopedSlots:_vm._u([{key:"filters",fn:function(ref){
var busy = ref.busy;
return [_c('table-filters',{attrs:{"fields":_vm.headers,"busy":busy,"list-filters":!_vm.employeeList ? _vm.listFilters : []},on:{"input":_vm.updateFilters},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})]}},{key:"actions",fn:function(){return [_vm._t("actions")]},proxy:true},{key:"default",fn:function(ref){
var items = ref.items;
return [_c('d-table',{attrs:{"items":items,"headers":_vm.headers,"filters":_vm.filters,"row-select":!_vm.employeeList,"row-action":true},on:{"update:filters":function($event){_vm.filters=$event},"onRowSelected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"item.lastName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":_vm.getEditLink(item)}},[_vm._v(" "+_vm._s(item.lastName)+" "+_vm._s(item.firstName)+" ")])]}},{key:"item.userRoles",fn:function(ref){
var item = ref.item;
return [(item.userRoles && item.userRoles[0])?[_vm._v(" "+_vm._s(_vm.$t('roles.' + item.userRoles[0].role))+" "),_c('br'),(item.userRoles[0].company)?_c('span',{staticClass:"lower"},[_vm._v(" "+_vm._s(item.userRoles[0].company.name)+" ")]):_vm._e(),(item.userRoles[0].restaurant)?_c('span',{staticClass:"lower"},[_vm._v(" "+_vm._s(item.userRoles[0].restaurant.name)+" ")]):_vm._e()]:_vm._e()]}},{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [(_vm.showActivateCheckboxes)?_c('checkbox-input',{staticClass:"mt-0",attrs:{"value":item.enabled},on:{"input":function($event){item.enabled
                                ? _vm.confirmDeactivation(item)
                                : _vm.confirmActivation(item)}}}):_vm._e()]}},{key:"rowActions",fn:function(ref){
                                var item = ref.item;
return [_c('edit-button',{attrs:{"to":_vm.getEditLink(item)}}),_c('delete-action',{attrs:{"entity-name":item.email,"entity-id":item.id,"api":_vm.api,"strong":"","pre-delete-hook":function () { return _vm.checkUserOrders(item.id); }},on:{"deleted":_vm.onEntityDeleted,"failed":_vm.onEntityDeleteFailed}})]}}],null,true)})]}},(!_vm.employeeList)?{key:"batchActions",fn:function(){return [_c('delete-batch-action',{attrs:{"selected-ids":_vm.selectedIds,"api":_vm.api,"strong":""},on:{"finished":_vm.onBatchDeleteFinished,"finishedWithErrors":_vm.onBatchDeleteFinishedWithErrors,"canceled":_vm.onBatchDeleteCanceled}})]},proxy:true}:null],null,true)}),_c('confirm-modal',{attrs:{"value":_vm.confirmActivationModal,"text":_vm.$t('texts.confirm_user_activation', {
                user: _vm.itemSelectedForActivation
                    ? ((_vm.itemSelectedForActivation.lastName) + " " + (_vm.itemSelectedForActivation.firstName))
                    : '',
            }),"processing":_vm.processingActivation},on:{"onApprove":_vm.activateEntity,"onCancel":_vm.resetActiveCheckboxes}}),_c('confirm-modal',{attrs:{"value":_vm.confirmDeactivationModal,"text":_vm.$t('texts.confirm_user_deactivation', {
                user: _vm.itemSelectedForActivation
                    ? ((_vm.itemSelectedForActivation.lastName) + " " + (_vm.itemSelectedForActivation.firstName))
                    : '',
            }),"processing":_vm.processingActivation},on:{"onApprove":_vm.deactivateEntity,"onCancel":_vm.resetActiveCheckboxes}}),_c('prevent-user-delete-dialog',{model:{value:(_vm.preventUserDeleteDialog),callback:function ($$v) {_vm.preventUserDeleteDialog=$$v},expression:"preventUserDeleteDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }